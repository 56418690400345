import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CustomEmojiPicker from './CustomEmojiPicker';
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import ImageWithRetry from './ImageWithRetry.js';
import Footer from './Footer';

const API_BASE_URL = 'https://emojisgame.com';

const EmojiImageGenerator = () => {
  const [selectedEmojis, setSelectedEmojis] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    let storedUserId = localStorage.getItem('userId');
    if (!storedUserId) {
      storedUserId = uuidv4();
      localStorage.setItem('userId', storedUserId);
    }
    setUserId(storedUserId);
    fetchImages(storedUserId);
  }, []);

  const fetchImages = useCallback(async (userId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/all_images_for_user`, {
        headers: {
          'user-id': userId,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setGeneratedImages(data.images);
      } else {
        console.error('Failed to fetch images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  }, []);

  const handleEmojiSelect = useCallback((emojis) => {
    setSelectedEmojis(emojis);
  }, []);

  const handleGenerateImage = useCallback(async (emojis) => {
    setIsLoading(true);
    setIsDialogOpen(true);

    try {
      const response = await fetch(`${API_BASE_URL}/api/generate-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'user-id': userId,
        },
        body: JSON.stringify({ emojis }),
      });

      if (response.ok) {
        const data = await response.json();
        pollForImage(data.id);
      } else {
        throw new Error('Failed to generate image');
      }
    } catch (error) {
      console.error('Error generating image:', error);
      setIsLoading(false);
      setCurrentImage({ status: 'failed' });
    }
  }, [userId]);

  const pollForImage = useCallback(async (imageId, attempts = 0) => {
    if (attempts > 30) {
      setIsLoading(false);
      setCurrentImage({ status: 'failed' });
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/image/${imageId}`, {
        headers: {
          'user-id': userId,
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.status === 'completed') {
          setIsLoading(false);
          setCurrentImage(data);
          setGeneratedImages((prev) => [data, ...prev]);
        } else if (data.status === 'failed') {
          setIsLoading(false);
          setCurrentImage({ status: 'failed', error: data.error });
        } else {
          setTimeout(() => pollForImage(imageId, attempts + 1), 2000);
        }
      } else {
        throw new Error('Failed to fetch image status');
      }
    } catch (error) {
      console.error('Error polling for image:', error);
      setTimeout(() => pollForImage(imageId, attempts + 1), 2000);
    }
  }, [userId]);

  const handleThumbnailClick = useCallback((image) => {
    setCurrentImage(image);
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const memoizedGeneratedImages = useMemo(() => generatedImages, [generatedImages]);

  return (
    <div className="container mx-auto p-4 flex flex-col items-center min-h-screen">
    <div className="w-full max-w-md mb-8">
      <CustomEmojiPicker 
        onEmojiSelect={handleEmojiSelect} 
        onGenerateImage={handleGenerateImage} 
        isLoading={isLoading}
      />
    </div>
    
    <Dialog isOpen={isDialogOpen} onOpenChange={handleCloseDialog}>
      <DialogContent className="w-full max-w-5xl max-h-[90vh] flex flex-col p-6">
        <div className="flex-grow flex items-center justify-center overflow-hidden">
          {isLoading ? (
            <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900"></div>
          ) : currentImage?.status === 'completed' ? (
            <div className="bg-white p-4 rounded-lg max-w-full max-h-full">
              <ImageWithRetry
                src={currentImage.url}
                alt="Generated"
                className="max-w-full max-h-[calc(90vh-10rem)] object-contain"
                userId={userId}
                API_BASE_URL={API_BASE_URL}
              />
            </div>
          ) : (
            <p>Failed to generate image. Please try again.</p>
          )}
        </div>
        <div className="mt-6 flex justify-end">
          <Button onClick={handleCloseDialog}>X</Button>
        </div>
      </DialogContent>
    </Dialog>

    <div className="w-full mt-8">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
        {memoizedGeneratedImages.map((image) => (
          <ImageWithRetry
            key={image.id}
            src={image.url}
            alt={`Generated ${image.id}`}
            className="w-full h-auto object-cover rounded-lg cursor-pointer"
            onClick={() => handleThumbnailClick(image)}
            userId={userId}
            API_BASE_URL={API_BASE_URL}
          />
        ))}
      </div>
    </div>
    <Footer />
  </div>
  );
};

export default React.memo(EmojiImageGenerator);
