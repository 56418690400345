import React, { useState, useCallback, useMemo } from "react";
import { Button } from "../../components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { ScrollArea } from "../../components/ui/scroll-area";
import emojiCategories from './EmojiCategories';

const CustomEmojiPicker = React.memo(({ onEmojiSelect, onGenerateImage, isLoading }) => {
  const [selectedEmojis, setSelectedEmojis] = useState([]);

  const handleEmojiClick = useCallback((emoji) => {
    setSelectedEmojis(prevEmojis => {
      const newEmojis = [...prevEmojis, emoji];
      onEmojiSelect(newEmojis.join(""));
      return newEmojis;
    });
  }, [onEmojiSelect]);

  const handleBackspace = useCallback(() => {
    setSelectedEmojis(prevEmojis => {
      const newEmojis = prevEmojis.slice(0, -1);
      onEmojiSelect(newEmojis.join(""));
      return newEmojis;
    });
  }, [onEmojiSelect]);

  const handleGenerateImage = useCallback(() => {
    onGenerateImage(selectedEmojis.join(""));
  }, [selectedEmojis, onGenerateImage]);

  const memoizedEmojiCategories = useMemo(() => Object.entries(emojiCategories), []);

  return (
    <div className="w-full max-w-md border rounded-lg shadow-sm">
      <div className="p-2 bg-blue-100 border-b">
        <ScrollArea className="h-16 whitespace-nowrap">
          <div className="text-3xl">{selectedEmojis.join("")}</div>
        </ScrollArea>
      </div>
      <Tabs defaultValue="faces" className="w-full">
        <div className="flex justify-between items-center px-1 py-2 bg-gray-50 border-b">
          <TabsList>
            {memoizedEmojiCategories.map(([category, { symbol }]) => (
              <TabsTrigger
                key={category}
                value={category}
                className="text-2xl p-2 focus:bg-gray-200"
              >
                {symbol}
              </TabsTrigger>
            ))}
          </TabsList>
          <Button
            variant="outline"
            size="icon"
            onClick={handleBackspace}
            className="p-1 text-xl text-red-800 bg-red-300 hover:bg-red-500"
          >
            &#x2190;
          </Button>
        </div>
        {memoizedEmojiCategories.map(([category, { emojis }]) => (
          <TabsContent key={category} value={category} className="m-0">
            <ScrollArea className="h-64">
              <div className="grid grid-cols-6 p-1">
                {emojis.map((emoji) => (
                  <Button
                    key={emoji}
                    variant="ghost"
                    className="p-0 h-12 w-12 hover:bg-gray-100"
                    onClick={() => handleEmojiClick(emoji)}
                  >
                    <span style={{ fontSize: "2rem" }}>{emoji}</span>
                  </Button>
                ))}
              </div>
            </ScrollArea>
          </TabsContent>
        ))}
      </Tabs>
      <Button
        className={`w-full h-16 pt-2 text-white rounded-t-none rounded-b-lg ${
          isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'
        }`}
        onClick={handleGenerateImage}
        disabled={isLoading}
      >
        <span style={{ fontSize: "3rem", margin: "0.5rem" }}>🤖 🖍️ 🖼️</span>
      </Button>
    </div>
  );
});

CustomEmojiPicker.displayName = "CustomEmojiPicker";

export default CustomEmojiPicker;