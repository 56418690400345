import React, { useState, useEffect, useCallback } from 'react';

const ImageWithRetry = ({ src, alt, className, onClick, userId, API_BASE_URL }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchImageWithRetry = useCallback(async (imageUrl, maxRetries = 3) => {
    for (let i = 0; i < maxRetries; i++) {
      try {
        const response = await fetch(`${API_BASE_URL}${imageUrl}`, {
          headers: {
            'user-id': userId,
          },
        });
        if (response.ok) {
          return URL.createObjectURL(await response.blob());
        }
        await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
      } catch (error) {
        console.error('Error fetching image:', error);
        if (i === maxRetries - 1) throw error;
      }
    }
    throw new Error('Max retries reached');
  }, [API_BASE_URL, userId]);

  useEffect(() => {
    let isMounted = true;
    let objectUrl;

    const loadImage = async () => {
      if (!src) return;

      setIsLoading(true);
      setIsError(false);

      try {
        objectUrl = await fetchImageWithRetry(src);
        if (isMounted) {
          setImageSrc(objectUrl);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setIsError(true);
          setIsLoading(false);
        }
      }
    };

    loadImage();

    return () => {
      isMounted = false;
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [src, fetchImageWithRetry]);

  if (isError) {
    return <div className={className}>Failed to load image</div>;
  }

  if (isLoading) {
    return <div className={className}>Loading...</div>;
  }

  return (
    <img src={imageSrc} alt={alt} className={className} onClick={onClick} />
  );
};

export default React.memo(ImageWithRetry);