const emojiCategories = {
    faces: {
      symbol: "😀",
      emojis: [
        "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍", 
        "🥰", "😘", "😗", "😙", "😚", "😋", "😛", "😝", "😜", "🤪", "🤨", "🧐", "🤓", "😎", "🤩", 
        "🥳", "🥺", "😢", "😳", "🥶", "😱", "🤗", "🤔", "🤭", "🤫", "😶", "😐", "😑",
        "😬", "🙄", "😯",  "🥱", "😴", "🤤", "😪", "🤐", "🤧", "😷", "🤒", "🤕", "🤑", "🤠", "😈",  "🤡", "👽", "👾",
        "🤖", "😺", "😸", "😹", "😻", "😼", "😽", "🙀", "😿", "😾"
      ],
    },
    animals: {
      symbol: "🐶",
      emojis: [
        "🐶", "🐱", "🐭", "🐹", "🐰", "🦊", "🐻", "🐼", "🐨", "🐯", "🦁", "🐮", "🐷", "🐸", "🐵",
        "🐔", "🐧", "🐦", "🐤", "🦆", "🦅", "🦉", "🦇", "🐺", "🐗", "🐴", "🦄", "🐝", "🐛", "🦋",
        "🐌", "🐞", "🐜", "🪲", "🪳", "🦗", "🕷", "🕸", "🦂", "🐢", "🐍", "🦎", "🦖", "🦕", "🐙",
        "🦑", "🦐", "🦞", "🦀", "🐡", "🐠", "🐟", "🐬", "🐳", "🐋", "🦈", "🐊", "🐅", "🐆", "🦓",
        "🦍", "🦧", "🦣", "🐘", "🦛", "🦏", "🐪", "🐫", "🦒", "🦘", "🦬", "🐃", "🐂", "🐄", "🐎",
        "🐖", "🐏", "🐑", "🦙", "🐐", "🦌", "🐕", "🐩", "🦮", "🐕‍🦺", "🐈", "🐈‍⬛", "🪶", "🐓", "🦃",
        "🦤", "🦚", "🦜", "🦢", "🦩", "🕊", "🐇", "🦝", "🦨", "🦡", "🦫", "🦦", "🦥", "🐁", "🐀",
        "🐿", "🦔"
      ],
    },
    nature: {
      symbol: "🌿",
      emojis: [
        "🌵", "🎄", "🌲", "🌳", "🌴", "🌱", "🌿", "☘️", "🍀", "🎍", "🎋", "🍃", "🍂", "🍁", "🍄", "🌾",
        "💐", "🌷", "🌹", "🥀", "🌺", "🌸", "🌼", "🌻", "🌞", "🌝", "🌛", "🌜", "🌚", "🌕", "🌖", "🌗",
        "🌘", "🌑", "🌒", "🌓", "🌔", "🌙", "🌎", "🌍", "🌏", "💫", "⭐️", "🌟", "✨", "⚡️", "💥", 
        "🔥", "🌈", "☀️", "🌤", "⛅️", "🌦", "🌧", "🌨", "🌩", "🌫", "🌬", "🌂", "☂️", "☔️"
      ],
    },
    hands: {
      symbol: "🖐",
      emojis: [
        "👋", "🤚", "🖐", "✋", "🖖", "👌", "🤌", "🤏", "✌️", "🤞", "🤟", "🤘", "🤙", "👈", "👉", "👆", "👇",
        "☝️", "👍", "👎", "✊", "👊", "🤛", "🤜", "👏", "🙌", "👐", "🤲", "🤝", "🙏", "✍️", "💅", "🤳", "💪",
        "🦾",
      ],
    },
    objects: {
      symbol: "☎️",
      emojis: [
        "⌚️", "📱", "💻", "⌨️", "🖥", "🖨", "🖲", "🕹", "🗜", "💽", "💾", "📼", "📷", "📸", "📹", "🎥", "📽", "🎞", "📞", "☎️", "📟", "📠", "📺", "📻", "🎙", "🎚", "🎛", "🧭",  
        "🔋", "🔌", "💡", "🔦", "🕯", "🗑", "💸", "💵", "💴", "💶", "💷", "💰", "💳", "💎", "⚖️",
        "🔧", "🔨", "🛠", "⛏", "🔩", "⚙️", "⚗️", "🔬", "🔭", "🚪", "🛏", "🛋", "🛒"
      ],
    },
    food: {
      symbol: "🍔",
      emojis: [
        "🍔", "🍏", "🍎", "🍐", "🍊", "🍋", "🍌", "🍉", "🍇", "🍓", "🍈", "🥭", "🍍", "🥥", "🥝", "🍅",
        "🥑", "🥦", "🥬", "🥒", "🌶", "🌽", "🥕", "🧄", "🧅", "🥔", "🍠", "🥐", "🥯", "🍞", "🥖", "🥨", 
        "🧀", "🥚", "🍳", "🥓", "🥩", "🍗", "🍖", "🌭", "🍕", "🥪", "🌮", "🌯", "🥙", "🧆", "🥘", "🍲",
        "🍛", "🍣", "🍱", "🥟", "🍤", "🍙", "🍚", "🍘", "🍥", "🥠", "🍢", "🍡", "🍧", "🍨", "🍦", "🥧",
        "🍰", "🎂", "🧁", "🍮", "🍭", "🍬", "🍫", "🍿", "🧂", "🥤", "🧃", "🧉", "🧊"
      ],
    },
    places: {
      symbol: "🏠",
      emojis: [
        "🏠", "🏡", "🏢", "🏦", "🏨", "🏪", "🏫", "🏬", "🏭", "🏯", "🏰",  "🗼",
        "🗽", "⛩", "⛲️", "⛺️", "🌁", "🌃", "🏙", "🌄", "🌅", "🌆", "🌇", "🏞",
        "🏜", "🏝", "🏖", "🏟", "🏛"
      ],
    },
    transportation: {
      symbol: "🚗",
      emojis: [
        "⛵️", "🚤", "🛳", "🛥", "🚢", "✈️", "🛩", "🛫", "🛬", "💺", "🚁", "🚟", "🚠", "🚡", "🛰",
        "🚀", "🛸", "🛶", "🚗", "🚕", "🚙", "🚌", "🚎", "🏎", "🚓", "🚑", "🚒", "🚐", "🛻", "🚚", "🚛",
        "🚜", "🛴", "🚲", "🛵", "🏍", "🛺", "🚨", "🚔", "🚍", "🚘", "🚖", "🚡", "🚠", "🚟", "🚃", "🚋",
        "🚞", "🚝", "🚄", "🚅", "🚈", "🚂", "🚆", "🚇", "🚊", "🚉"
      ],
    },
    activities: {
      symbol: "⚽️",
      emojis: [
        "⚽️", "🏀", "🏈", "⚾️", "🥎", "🎾", "🏐", "🏉", "🥏", "🎱", "🪀", "🏓", "🏸", "🏒", "🏑", "🥍",
        "🏏", "🪃", "🥅", "⛳️", "🪁", "🎣", "🤿", "🥊", "🥋", "🎽", "🛹", "🛼", "🛷", "⛸", "🥌",
        "🎿", "⛷", "🏂", "🏋️‍♂️", "🏋️‍♀️", "🤼‍♂️", "🤼‍♀️", "🤸‍♂️", "🤸‍♀️", "⛹️‍♂️", "⛹️‍♀️", "🤾‍♂️", "🤾‍♀️", "🏌️‍♂️", "🏌️‍♀️", "🏇",
        "🧘‍♂️", "🧘‍♀️", "🏄‍♂️", "🏄‍♀️", "🏊‍♂️", "🏊‍♀️", "🤽‍♂️", "🤽‍♀️", "🚣‍♂️", "🚣‍♀️", "🧗‍♂️", "🧗‍♀️", "🚵‍♂️", "🚵‍♀️", "🚴‍♂️", "🚴‍♀️",
        "🏆", "🥇", "🥈", "🥉", "🏅", "🎖", "🏵", "🎗", "🎫", "🎟", "🎭", "🎨", "🎬", "🎤", "🎧", "🎼",
        "🎹", "🥁", "🎷", "🎺", "🎸", "🎻", "🎲", "♟", "🎯", "🎳", "🎮"
      ],
    },
    symbols: {
        symbol: "🔣",
        emojis: [
          "❤️", "🧡", "💛", "💚", "💙", "💜", "🖤", "🤍", "🤎", "💔", "❣️", "💕", "💞", "💓", "💗", "💖", "💘",
          "💝", "💟", "☮️", "◀️", "▶️", "🔺", "🔻", "🔸", "🔹", "🔶", "🔷", "🔳", "🔲", "▪️", "▫️", "◾", "◽", "◼️",
          "◻️", "⬛", "⬜", "🔲", "🔳", "🔴", "🔵", "⚫", "⚪", "⬤", "🔀", "🔁", "🔂", "⏩", "⏭️", "⏯️", "⏪", "⏮️",
          "🔼", "⏫", "🔽", "⏬",
        ],
      }
    };
  
  export default emojiCategories;