import React from 'react';
import EmojiPickerDemo from './components/EmojiPicker';

function App() {
  return (
    <div className="pt-4 container mx-auto text-center text-gray-600">
      <h1 className="font-bold mb-4">emojis game 😺🎲</h1>
      <EmojiPickerDemo />
    </div>
    
  );
}

export default App;