import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-100 mt-8 py-1">
      <div className="container mx-auto text-center text-gray-600">
        <p className="my-1">Created with ❤️ by dad for 🪐 + ☀️</p>
      </div>
    </footer>
  );
};

export default Footer;